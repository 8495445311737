import React, { useState } from "react";
import { ColumnConfig } from "../../../common/types/typing";
import Layout from "../../../components/layout";
import TableBody from "../../../components/UI-Elements/Table/Body";
import TableHeader from "../../../components/UI-Elements/Table/Header";
import TableRow from "../../../components/UI-Elements/Table/Row";
import Table from "../../../components/UI-Elements/Table/Table";
import coinList from "../../../common/data/coins-list.json";
import Link from "../../../components/UI-Elements/Navigation/Link";
import Title from "@components/UI-Elements/Typography/Title";
import SEO from "@components/seo";
import { Section } from "@components/UI-Elements/General";

type CoinData = {
  id: string;
  name: string;
  symbol: string;
};
const config: ColumnConfig<CoinData>[] = [
  {
    key: "id",
    title: "id",
    render: (data) => data.id,
  },
  {
    key: "name",
    title: "name",
    render: (data) => data.name,
  },
  {
    key: "symbol",
    title: "symbol",
    render: (data) => data.symbol,
  },
  {
    key: "open",
    title: "open",
    render: (data) => <Link href={`https://www.coingecko.com/en/coins/${data.id}`} type="button" external>Coingecko</Link>,
  },
];

const Page = () => {
  const [q, setQ] = useState("");
  const CoinRow: React.FC<{ coin: CoinData; columnConfig: ColumnConfig<CoinData>[] }> = ({ coin, columnConfig }) => {
    const columns = columnConfig.map((column) => column.render(coin));

    return <TableRow columns={columns} />;
  };
  const filtered = q ? coinList.filter(({ name }) => name.toLowerCase().includes(q.toLowerCase())) : coinList;

  return (
    <Layout contentWidth="full">
      <SEO title="Coins auf Coingecko" robots="noindex" />
      <Title>Coins auf Coingecko</Title>
      <div className="my-6">
        <label htmlFor="search" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          Suche
        </label>
        <input
          type="text"
          id="search"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Bitcoin, Ethereum, ..."
          value={q}
          onChange={(e) => setQ(e.target.value)}
          required
        />
      </div>
      <Section>
        <Table>
          <TableHeader columns={config} />
          <TableBody>
            {filtered.slice(0, 100).map((coin, index) => (
              <CoinRow key={index} coin={coin} columnConfig={config} />
            ))}
          </TableBody>
        </Table>
      </Section>
    </Layout>
  );
};

export default Page;
