import React from "react";

type Props = {
  columns: (string | React.ReactNode)[];
  loading?: boolean;
};
const TableRow: React.FC<Props> = ({ columns, loading }) => {
  return (
      <tr className={`border-b odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700 dark:border-gray-600 ${loading ? 'animate-pulse' : ''}`}>
        {columns.map((column, index) => (
          <td key={index} className="py-2 px-5 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {loading ? <div className="h-4 bg-gray-300 mb-6 rounded"></div> : column}
          </td>
        ))}
      </tr>
  );
};
export default TableRow;
